
import CompensarionBoxRegister from '@/components/queries/molecules/CompensationBoxRegister.vue';
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdminBox from '@/components/queries/molecules/AdminBox.vue'
import CardEmergency from "@/components/general/molecules/CardEmergency.vue";
import { useAuthStore } from "@/stores/auth";
import Header from "@/components/general/molecules/Header.vue";

export default defineComponent({
  setup() {
    const showAdminBox = ref<boolean>(false);
    const authStore = useAuthStore();
    const authUser = authStore.user;
    const authUserCommunity = authStore.userCommunity;
    const route = useRoute();
    const router = useRouter();
    const actionResponse = ref<string>('')
    const itemServiceId = ref<number>(0)
    const typeModal = ref<string>('')
    
    const showCommitte = ref<boolean>(true);
    const showRegister = ref<boolean>(false)

    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const actionServices = (action: any, itemId: any, type: any) => {
      closeModal()
      typeModal.value = type
      actionResponse.value = action
      itemServiceId.value = itemId
      showRegister.value = true
    }
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/queries`,
        name: "Consultas",
      },
      { path: ``, name: "Administración y comité" },
    ];
    const committeData = [
      {
        id: 1,
        figure: "queries/account-lock",
        title: "Integrantes del Comité",
        styleCard: "bg-success bg-gradient-to-b from-cardMenu text-4xl",
      },
      {
        id: 2,
        figure: "queries/account-lock",
        title: "Personal de Servicio",
        styleCard: "bg-success bg-gradient-to-b from-cardMenu text-4xl",
      },
      {
        id: 3,
        figure: "queries/account-lock",
        title: "Administración del edificio ",
        styleCard: "bg-success bg-gradient-to-b from-cardMenu text-4xl",
      },
    ];
    const closeModal = () => {
      showAdminBox.value = false
      showRegister.value = false

    }

    async function goCommitteOption(index: number) {
      switch (index) {
        case 1:
          router.push({
            path: `/community/${communityId.value}/block/${blockId.value}/queries/committe/committe-user-list`,
          });
          break;
        case 2:
          router.push({
            path: `/community/${communityId.value}/block/${blockId.value}/queries/committe/personal-service`,
          });
          break;
        case 3:
          showAdminBox.value = true
          break;
      }
    }

    return {
      showAdminBox,
      typeModal,
      committeData,
      itemServiceId,
      showRegister,
      goCommitteOption,
      actionServices,
      closeModal,
      actionResponse,
      showCommitte,
      breadcrumbRoutes,
      authUser,
      authUserCommunity,
    };
  },
  components: { CardEmergency, Header, AdminBox, CompensarionBoxRegister }
});
